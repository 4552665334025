<div class="view">
  <video [ngClass]="{active: !value}" autoplay (loadeddata)="streamInitialized = true" #video></video>
  <video [ngClass]="{active: !!value}" #preview controls></video>
</div>

<div class="buttons" *ngIf="streamInitialized">
  <ng-container *ngIf="!value">
    <app-icon-button *ngIf="!isRecording" class="capture-btn record-btn" (click)="startCapture()"  icon="/assets/record.svg"></app-icon-button>
    <app-icon-button *ngIf="isRecording" class="capture-btn stop-btn" (click)="stopCapture()"  icon="/assets/stop.svg"></app-icon-button>
  </ng-container>

  <ng-container *ngIf="!!value">
    <app-button class="bg-gray" (click)="reset()" i18n="CaptureModal|again button@@againButton">Opnieuw</app-button>
    <app-button class="ml-2" (click)="approve()" i18n="CaptureModal|ok button@@okButton">OK</app-button>
  </ng-container>
</div>
