import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IClub } from '../reports/clubs/interfaces/club.interface';
import { ClubsState } from '../reports/clubs/states/clubs.state';
import {
  DiscriminationTypesState,
  IDiscriminationType,
} from '../reports/discrimination-types';
import {IIncidentType, IncidentTypesState} from '../reports/incident-types';
import {IReportType, ReportTypesState} from '../reports/report-types';
import {
  ResetReport,
  SetReportType,
} from '../reports/states/report/report.actions';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  @Select(DiscriminationTypesState)
  public discriminationTypes$?: Observable<IDiscriminationType[]>;

  @Select(ReportTypesState)
  public reportTypes$?: Observable<IReportType[]>;

  @Select(IncidentTypesState)
  public incidentTypes$?: Observable<IIncidentType[]>;

  @Select(ClubsState)
  public clubs$?: Observable<IClub[]>;

  public all$: Observable<any>;

  public constructor(private store: Store) {
    this.all$ = combineLatest([
      this.discriminationTypes$,
      this.reportTypes$,
      this.incidentTypes$,
      this.clubs$
    ]).pipe(
      map(([discriminationTypes, reportTypes, incidentTypes, clubs]: any) => {
        if (
          (!discriminationTypes || discriminationTypes.length === 0) ||
          (!reportTypes || reportTypes.length === 0) || 
          (!incidentTypes || incidentTypes.length === 0) || 
          (!clubs || clubs.length === 0)
        ) {
          return false;
        }

        return true;
      })
    );
  }

  public async createReport(reportType: string): Promise<void> {
    if (reportType === 'general') {
      return await firstValueFrom(
        this.store.dispatch(new ResetReport()).pipe(
          switchMap(() => this.store.dispatch(new SetReportType(reportType))),
          switchMap((state) =>
            this.store.dispatch(
              new Navigate([`/reports/${state.report.reportType.slug}/1`])
            )
          )
        )
      );
    }

    return await firstValueFrom(this.store.dispatch(new Navigate(['/reports/soccer'])));
  }
}
