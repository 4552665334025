import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContactWithAdbPageComponent } from 'src/app/reports/pages/contact-with-adb-page/contact-with-adb-page.component';
import { ReportState } from 'src/app/reports/states/report/report.state';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public constructor(private store: Store, private route: ActivatedRoute) {}

  private getLastChild(): ActivatedRoute {
    let route = this.route;

    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  private getTypeSlug(): string {
    let route = this.getLastChild();
    while (route.parent) {
      const data = route.snapshot.data;
      if (data.typeSlug) {
        return data.typeSlug;
      }

      route = route.parent;
    }

    return 'general';
  }

  public goToPrev(): Observable<any> {
    const route = this.getLastChild();
    const typeSlug = this.getTypeSlug();
    const currentStep = parseInt(route.snapshot.url.toString(), 10);
    const prevStep = currentStep - 1;
    const report = this.store.selectSnapshot(ReportState);

    if (
      route.component === ContactWithAdbPageComponent &&
      !report.contactWithAdb
    ) {
      return this.store.dispatch(new Navigate([`/reports/${typeSlug}/6`]));
    }

    if (prevStep <= 0) {
      return this.store.dispatch(
        new Navigate([`/`])
      );
    }

    return this.store.dispatch(
      new Navigate([`/reports/${typeSlug}/${prevStep}`])
    );
  }

  public goToNext(): Observable<any> {
    const route = this.getLastChild();
    const typeSlug = this.getTypeSlug();
    const currentStep = parseInt(route.snapshot.url.toString(), 10);
    const nextStep = currentStep + 1;
    const report = this.store.selectSnapshot(ReportState);

    if (
      route.component === ContactWithAdbPageComponent &&
      !report.contactWithAdb
    ) {
      return this.store.dispatch(new Navigate([`/reports/${typeSlug}/9`]));
    }

    return this.store.dispatch(
      new Navigate([`/reports/${typeSlug}/${nextStep}`])
    );
  }
}
