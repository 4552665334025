import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClubsService {
  public constructor(private http: HttpClient) {}

  public fetchAll(): Observable<Array<any>> {
    return this.http
      .get<Array<any>>(`${environment.apiBaseUrl}/clubs`)
      .pipe(
        map((items: Array<any>) => items.sort((a, b) => {
          if (a.name === b.name) {
            return 0;
          }

          return a.name > b.name ? 1 : -1;

        }))
      );
  }
}
