import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IIncidentType } from 'src/app/reports/incident-types';
import { OptionPickerComponent } from '../option-picker/option-picker.component';

@Component({
  selector: 'app-incident-type-picker',
  templateUrl: '../option-picker/option-picker.component.html',
  styleUrls: ['../option-picker/option-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncidentTypePickerComponent),
      multi: true,
    },
  ],
})
export class IncidentTypePickerComponent extends OptionPickerComponent<IIncidentType> {}
