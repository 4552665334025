import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { firstValueFrom, switchMap } from 'rxjs';
import { AgreeToPrivacyStatement } from '../../states/privacy-statement/privacy-statement.actions';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss'],
})
export class PrivacyPageComponent {
  @HostBinding('class.agree-page')
  public agreePage!: boolean;

  public constructor(private store: Store, private route: ActivatedRoute) {
    this.agreePage = this.route.snapshot.data.agreePage === true;
  }

  public async continue(): Promise<void> {
    await firstValueFrom(
      this.store
      .dispatch(new AgreeToPrivacyStatement())
      .pipe(switchMap(() => this.store.dispatch(new Navigate(['/']))))
    );
  }
}
