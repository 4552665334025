import { IClub } from '../../clubs/interfaces/club.interface';
import { IDiscriminationType } from '../../discrimination-types';
import { IIncidentType } from '../../incident-types';
import { IEvidence } from '../../interfaces/evidence.interface';
import { IReport } from '../../interfaces/report.interface';

export class ResetReport {
  public static readonly type = '[Report] Reset report';
}

export class SetReportType {
  public static readonly type = '[Report] Set report type';
  public constructor(public slug: string) {}
}

export class SetAbout {
  public static readonly type = '[Report] Set about';
  public constructor(public about: string) {}
}

export class SetDiscriminationTypes {
  public static readonly type = '[Report] Set discrimination types';
  public constructor(public discriminationTypes: Array<IDiscriminationType>) {}
}

export class SetIncidentTypes {
  public static readonly type = '[Report] Set incident types';
  public constructor(public incidentTypes: Array<IIncidentType>) {}
}

export class SetIncidentDetails {
  public static readonly type = '[Report] Set incident details';

  public constructor(
    public description: string,
    public reportDate?: Date,
    public recording?: IEvidence
  ) {}
}

export class SetLocation {
  public static readonly type = '[Report] Set location';

  public constructor(
    public location?: string,
    public latitude?: number,
    public longitude?: number
  ) {}
}

export class SetLocationIncident {
  public static readonly type = '[Report] Set location incident';
  public constructor(public locationIncident?: string) {}
}

export class UpdateEvidence {
  public static readonly type = '[Report] Update evidence';
  public constructor(
    public evidenceType: string,
    public evidenceItems: Array<any>
  ) {}
}

export class SetReporterPostalCode {
  public static readonly type = '[Report] Set reporter postal code';
  public constructor(public reporterPostalCode: string) {}
}

export class SetContactInfo {
  public static readonly type = '[Report] Set contact info';

  public constructor(
    public reporterPhoneNumber: string,
    public reporterEmail: string
  ) {}
}

export class SetClub {
  public static readonly type = '[Report] Set club';
  public constructor(public club: IClub) {}
}

export class SetSection {
  public static readonly type = '[Report] Set section';
  public constructor(public section?: string) {}
}

export class SetRow {
  public static readonly type = '[Report] Set row';
  public constructor(public row?: string) {}
}

export class SetSeat {
  public static readonly type = '[Report] Set seat';
  public constructor(public seat?: string) {}
}

export class SetShareWithClub {
  public static readonly type = '[Report] Set share with club';
  public constructor(public shareWithClub: boolean) {}
}

export class SetContactWithAdb {
  public static readonly type = '[Report] Set contact with adb';
  public constructor(public contactWithAdb: boolean) {}
}

export class SendReport {
  public static readonly type = '[Report] Send report';

  public constructor(public report: IReport) {}
}
