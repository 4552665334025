import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { v4 } from 'uuid';
import { OtherDialogComponent } from '../other-dialog/other-dialog.component';
import slugify from 'slugify';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-option-picker',
  templateUrl: './option-picker.component.html',
  styleUrls: ['./option-picker.component.scss'],
})
export class OptionPickerComponent<T extends { id: string; label: string }>
  implements ControlValueAccessor, OnChanges {
  @Input()
  public options?: Array<T> | null;

  public value?: Array<T> | null;
  public onChange = (_: Array<T> | null): void => {};
  public onTouch = (_: any): void => {};

  public customValue?: any;

  public selectedOptions: any = {};

  public constructor(private dialog: MatDialog) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.processSelected(changes.options.currentValue, this.value);
    }
  }

  public writeValue(selectedOptions: Array<T>): void {
    this.value = selectedOptions;
    this.processSelected(this.options, this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public toggleSelection(option: any): void {
    const alreadySelected = !!this.selectedOptions[option.id];
    if (alreadySelected) {
      delete this.selectedOptions[option.id];
    }

    if (!alreadySelected) {
      this.selectedOptions[option.id] = option;
    }

    this.updateValue();
  }

  public async openDialog(): Promise<void> {
    const ref = this.dialog.open(OtherDialogComponent, {
      data: {
        title: $localize`:@@otherDialogOptionTitle:Ander discriminatietype`,
        description: $localize`:@@otherDialogOptionDescripton:Anders, namelijk...`,
        value: this.customValue?.label,
      },
    });

    let customOption = await firstValueFrom(ref.afterClosed());

    if (!!customOption) {
      customOption = {
        id: v4(),
        isCustom: 1,
        slug: slugify(customOption, { lower: true }),
        label: customOption,
        position: 1,
      };
    }

    this.customValue = customOption;
    this.updateValue();
  }

  private updateValue(): void {
    const selectedOptions = Object.values(this.selectedOptions) as Array<T>;

    if (!!this.customValue) {
      selectedOptions.push(this.customValue);
    }

    if (!selectedOptions || selectedOptions.length === 0) {
      this.onChange(null);
      return;
    }

    this.onChange(selectedOptions);
  }

  private processSelected(options: any, selectedOptions: any): void {
    if (!options || !selectedOptions) {
      return;
    }

    const proccessedValues = [];
    for (const option of selectedOptions || []) {
      const match = options?.find((o: any) => o.id === option.id);

      if (!match) {
        this.customValue = option;
        continue;
      }

      proccessedValues[option.id] = option;
    }

    this.selectedOptions = proccessedValues;
  }
}
