import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { v4 } from 'uuid';
import { MediaType } from '../media-type';

@Injectable({
  providedIn: 'root',
})
export class RecorderService {
  private recorder?: RecordRTC;
  private stream?: MediaStream;

  public get isRecording(): boolean {
    return !!this.recorder;
  }

  public async startRecording(mediaType: MediaType): Promise<MediaStream> {
    return new Promise(async (resolve, reject) => {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: [MediaType.Video, MediaType.Image].includes(mediaType),
          audio: [MediaType.Video, MediaType.Audio].includes(mediaType),
        });

        const type = MediaType.Image === mediaType ? 'video' : mediaType;
        this.recorder = new RecordRTC(this.stream, { type });
        this.recorder.startRecording();

        resolve(this.stream);
      } catch (e) {
        reject(e);
      }
    });
  }

  public stopRecording(): Promise<File> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.recorder) {
          reject('No active recoding session found');
        }

        this.recorder?.stopRecording(async () => {
          const file = await this.getFile();
          this.destroy();
          resolve(file);
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  private destroy(): void {
    for (const track of this.stream?.getTracks() ?? []) {
      track.stop();
    }

    this.recorder?.reset();
    this.recorder?.destroy();
    this.recorder = undefined;
  }

  private async getFile(): Promise<File> {
    const blob: any = await this.getBlob();
    return new File([blob], v4(), {
      type: blob.type,
    });
  }

  private getBlob(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {
        const blob = this.recorder?.getBlob();

        if (!blob) {
          reject('No blob data found for recording session');
        }

        resolve(<Blob>blob);
      } catch (e) {
        reject(e);
      }
    });
  }
}
