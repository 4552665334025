export const TRANSLATIONS = {
  discriminationType: [
    {
      key: 'age',
      translation: $localize`:@@discriminationTypeAgeLabel:Leeftijd`
    }, {
      key: 'sexual-preference',
      translation: $localize`:@@discriminationTypeSexualPreferenceLabel:Seksuele voorkeur`,
    }, {
      key: 'religion',
      translation: $localize`:@@discriminationTypeReligionLabel:Geloof`,
    }, {
      key: 'handicap',
      translation: $localize`:@@discriminationTypeHandicapLabel:Handicap`,
    }, {
      key: 'gender',
      translation: $localize`:@@discriminationTypeGenderLabel:Geslacht`,
    }, {
      key: 'skin-color',
      translation: $localize`:@@discriminationTypeSkinColor:Huidskleur`,
    }
  ],
  incidentType: [
    {
      key: 'exclusion',
      translation: $localize`:@@incidentTypeExclusionLabel:Uitsluiting`,
    }, {
      key: 'unfair-treatment',
      translation: $localize`:@@incidentTypeUnfairTreatmentLabel:Ongelijke behandeling`,
    }, {
      key: 'swearing',
      translation: $localize`:@@incidentTypeSwearingLabel:Schelden`,
    }, {
      key: 'threat',
      translation: $localize`:@@incidentTypeThreatLabel:Bedreiging`,
    }, {
      key: 'destruction',
      translation: $localize`:@@incidentTypeDestructionLabel:Vernieling`,
    }
  ]
}
