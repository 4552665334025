import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { PrivacyStatementState } from './states/privacy-statement/privacy-statement.state';
import { HttpClient } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { PrivacyPageComponent } from './components/privacy-page/privacy-page.component';
import { UiModule } from '../ui/ui.module';

@NgModule({
  declarations: [PrivacyPageComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([PrivacyStatementState]),
    MarkdownModule.forRoot({ loader: HttpClient }),
    UiModule,
  ],
  exports: [PrivacyPageComponent],
})
export class PrivacyModule {}
