import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './about-page/about-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PrivacyPageComponent } from './privacy/components/privacy-page/privacy-page.component';
import { AcceptedPrivacy } from './reports/guards/accepted-privacy';
import { GeneralLayoutComponent } from './reports/layouts';

const routes: Routes = [
  {
    path: 'privacy-statement',
    data: {
      agreePage: true,
    },
    component: PrivacyPageComponent,
  },
  {
    path: '',
    canActivate: [AcceptedPrivacy],
    children: [
      {
        path: '',
        component: HomePageComponent,
      },
      {
        path: 'about',
        component: GeneralLayoutComponent,
        children: [
          {
            path: '',
            component: AboutPageComponent,
          },
        ],
      },
      {
        path: 'privacy',
        component: GeneralLayoutComponent,
        children: [
          {
            path: '',
            component: PrivacyPageComponent,
          },
        ],
      },
      {
        path: 'reports',
        loadChildren: (): any =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [AcceptedPrivacy],
})
export class AppRoutingModule {}
