import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { firstValueFrom, from, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MediaType } from '../../media-type';
import { FileConverterService } from '../../services/file-converter.service';
import { RecorderService } from '../../services/recorder.service';
import {
  StartRecording,
  StopRecording,
} from '../../states/recoder/recorder.actions';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements AfterViewInit {
  @ViewChild('video', { read: ElementRef })
  public video!: ElementRef<HTMLVideoElement>;

  @ViewChild('preview', { read: ElementRef })
  public preview!: ElementRef<HTMLVideoElement>;

  public value?: string;

  public streamInitialized = false;

  public isRecording = false;

  public constructor(
    private store: Store,
    private recorderService: RecorderService,
    private fileConverter: FileConverterService,
    private dialogRef: MatDialogRef<VideoModalComponent>
  ) {
    this.dialogRef
      .beforeClosed()
      .pipe(
        switchMap(() => of(this.recorderService.isRecording)),
        filter((isRecording) => isRecording),
        switchMap(() => from(this.recorderService.stopRecording()))
      )
      .subscribe();
  }

  public async ngAfterViewInit(): Promise<void> {
    const stream = await this.recorderService.startRecording(MediaType.Video);
    this.video.nativeElement.srcObject = stream;
  }

  public reset(): void {
    this.value = undefined;
  }

  public async approve(): Promise<void> {
    await this.recorderService.stopRecording();
    this.dialogRef.close(this.value);
  }

  public async stopCapture(): Promise<void> {
    if (!this.isRecording) {
      return;
    }
    await firstValueFrom(
      this.store.dispatch(new StopRecording()).pipe(
        map((state: any) => state.recorder.data),
        filter((file: File) => !!file),
        tap((file: File) => {
          const url = URL.createObjectURL(file);
          this.preview.nativeElement.src = url;
        }),
        switchMap((file: File) => from(this.fileConverter.fileToBase64(file))),
        tap((data: string) => (this.value = data)),
        tap(() => (this.isRecording = false))
      )
    );
  }

  public startCapture(): void {
    this.isRecording = true;
    this.store.dispatch(new StartRecording(MediaType.Video));
  }
}
