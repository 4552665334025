import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IReport } from '../../interfaces/report.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public constructor(private http: HttpClient) {}

  public send(reportType: string, data: FormData): Observable<IReport> {
    return this.http.post<IReport>(
      `${environment.apiBaseUrl}/reports/${reportType}`,
      data,
      {
        headers: {
          'app-platform': 'web',
          'app-build-number': '1',
        },
      }
    );
  }
}
