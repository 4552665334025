import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent implements AfterViewChecked {
  @Input()
  public backgroundColor?: string;

  @HostBinding('style.height')
  @Input()
  public height?: string;

  @Input()
  public heightFactor = 1;

  @ViewChild('svg')
  public svg?: any;

  public constructor(private renderer: Renderer2, private el: ElementRef) {}

  public get removeAttributes(): any {
    return ['stroke-width', 'stroke', 'fill'];
  }

  public get svgAttributes(): any {
    return {
      'stroke-width': 0,
      stroke: 'transparent',
      fill: this.backgroundColor,
    };
  }

  public ngAfterViewChecked(): void {
    const pathEl = this.svg?.nativeElement?.firstChild?.querySelector('#path');

    if (!pathEl) {
      return;
    }

    this.renderer.setAttribute(pathEl, 'fill', this.backgroundColor || 'black');
  }

  public init(options: any): void {
    const styles = new Map<string, string>();
    styles.set('height', `${options.tileHeight}px`);
    styles.set('height', `${options.tileHeight}px`);
    styles.set('z-index', `${options.tiles.length - options.index}`);
    styles.set('height', `${options.tileHeight * this.heightFactor}px`);
    if (options.isLast) {
      styles.set('backgroundColor', this.backgroundColor || 'white');
    }

    this.setStyles(this.el.nativeElement, styles);

    if (options.isLast) {
      this.renderer.addClass(this.el.nativeElement, 'last');
    }
  }

  private setStyles(el: any, styles: Map<string, string>): void {
    for (const key of styles.keys()) {
      this.renderer.setStyle(el, key, styles.get(key));
    }
  }
}
