import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './components/icon/icon.component';
import { InlineSVGModule } from 'ng-inline-svg';

const components = [IconComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, InlineSVGModule.forRoot()],
  exports: components,
})
export class IconsModule {}
