import { Component, Inject } from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-other-dialog',
  templateUrl: './other-dialog.component.html',
  styleUrls: ['./other-dialog.component.scss'],
})
export class OtherDialogComponent {
  public title?: string;
  public description?: string;

  public valueControl = new FormControl(null, [Validators.maxLength(255)]);
  public constructor(
    private dialogRef: MatDialogRef<OtherDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { value: string; title: string; description: string; validators: ValidatorFn[] }
  ) {
    if (!!data?.value) {
      this.valueControl.setValue(this.data.value);
    }

    if (!!data?.validators) {
      this.valueControl.setValidators(this.data.validators);
    }

    if (!!data?.title) {
      this.title = data.title;
    }

    if (!!data?.description) {
      this.description = data.description;
    }
  }

  public close(): void {
    if (this.valueControl.invalid) {
      return;
    }

    const value = this.valueControl.value;
    this.dialogRef.close(value);
  }
}
