import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { from, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MediaType } from '../../media-type';
import { RecorderService } from '../../services/recorder.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements AfterViewInit {
  @ViewChild('video', { read: ElementRef })
  public video!: ElementRef<HTMLVideoElement>;

  @ViewChild('canvas', { read: ElementRef })
  public canvas!: ElementRef<HTMLCanvasElement>;

  public value?: string;

  public streamInitialized = false;

  public constructor(
    private recorderService: RecorderService,
    private dialogRef: MatDialogRef<ImageModalComponent>
  ) {
    this.dialogRef
      .beforeClosed()
      .pipe(
        switchMap(() => of(this.recorderService.isRecording)),
        filter((isRecording) => isRecording),
        switchMap(() => from(this.recorderService.stopRecording()))
      )
      .subscribe();
  }

  public async ngAfterViewInit(): Promise<void> {
    const stream = await this.recorderService.startRecording(MediaType.Image);
    this.video.nativeElement.srcObject = stream;
  }

  public reset(): void {
    this.value = undefined;
  }

  public async approve(): Promise<void> {
    await this.recorderService.stopRecording();
    this.dialogRef.close(this.value);
  }

  public capture(): void {
    const canvas = this.canvas.nativeElement;
    const ctx = canvas.getContext('2d');
    canvas.width = this.video.nativeElement.videoWidth;
    canvas.height = this.video.nativeElement.videoHeight;
    ctx?.drawImage(this.video.nativeElement, 0, 0);
    this.value = canvas.toDataURL('image/jpeg');
  }
}
