import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsComponent } from './components/cards/cards.component';
import { CardComponent } from './components/card/card.component';
import { BodyCardComponent } from './components/body-card/body-card.component';

@NgModule({
  declarations: [CardsComponent, CardComponent, BodyCardComponent],
  imports: [CommonModule],
  exports: [CardsComponent, CardComponent, BodyCardComponent],
})
export class CardsModule {}
