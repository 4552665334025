<app-tile backgroundColor="#ffffff" height="20vh">
  <h1
    class="h1 text-uppercase text-primary"
    i18n="Privacy|privacy title@@privacyTitle">
    Privacy
  </h1>
</app-tile>

<div class="page-container">
  <app-body-card>
    <p
      *ngIf="agreePage"
      i18n="Privacy|privacy scroll down@@privacyScrollDown">
      Voor je verder gaat, vragen we je om akkoord te gaan met de  privacyverklaring.
    </p>

    <markdown
      src="/assets/translations/nl_privacy_statement.md"
      ngPreserveWhitespaces>
    </markdown>

    <app-button
      *ngIf="agreePage"
      (click)="continue()"
      i18n="Privacy|agree button@@privacyAgreeButton">
      Ik ga akkoord
    </app-button>
  </app-body-card>
</div>
