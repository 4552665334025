import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MediaModule } from '../media/media.module';
import { GeolocationModule } from '../geolocation/geolocation.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NavigationService } from './services/navigation.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MediaModule,
    GeolocationModule,
    MatSlideToggleModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  providers: [NavigationService],
})
export class SharedModule {}
