import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { DiscriminationTypesService } from '../services/discrimination-types.service';
import { FetchDiscriminationTypes } from './discrimination-types.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@State<Array<any>>({
  name: 'discriminationTypes',
  defaults: [],
})
@Injectable()
export class DiscriminationTypesState implements NgxsOnInit {
  public constructor(
    private store: Store,
    private discriminationTypeService: DiscriminationTypesService
  ) {}

  public ngxsOnInit(): void {
    this.store.dispatch(new FetchDiscriminationTypes());
  }

  @Action(FetchDiscriminationTypes)
  public fetchDiscriminationTypes(ctx: StateContext<Array<any>>): Observable<any> {
    return this.discriminationTypeService
      .fetchAll()
      .pipe(
        tap((discriminationTypes: Array<any>) =>
          ctx.setState(discriminationTypes)
        )
      );
  }
}
