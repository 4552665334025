<div class="col device-options">
  <app-image-input (dataReady)="addEvidence('image', $event)">
    <div class="text-black" pristine dirty i18n="Evidence|take photo label@@takePhotoLabel">Maak foto</div>
  </app-image-input>

  <app-video-input (dataReady)="addEvidence('video', $event)">
    <div class="text-black" pristine dirty i18n="Evidence|record video label@@recordVideoLabe">Maak video</div>
  </app-video-input>

  <app-audio-input (busy)="isBusy$.next($event)" (dataReady)="addEvidence('audio', $event)">
    <div class="text-black" pristine dirty i18n="Evidence|record audio label@@recordAudioLabel">Audio opnemen</div>
    <div class="text-black" busy i18n="Evidence|stop recording label@@stopRecordingLabel">Stop opname</div>
  </app-audio-input>
</div>

<div class="col file-options">
  <label>
    <app-icon-button icon="/assets/camera.svg"> </app-icon-button>
    <input type="file" accept="image/*" (change)="onFileSelected('image', $event)" />
    <span class="text-black" i18n="Evidence|pick photo label@@pickPhotoLabel">Kies foto</span>
  </label>

  <label>
    <app-icon-button icon="/assets/play.svg"> </app-icon-button>
    <input type="file" accept="video/*" i18n="Evidence|pick video label@@pickVideoLabel" (change)="onFileSelected('video', $event)" />
    <span class="text-black">Kies video</span>
  </label>
</div>

<div class="text-black my-2" busy i18n="Evidence|file count label@@fileCountLabel">Aantal bestanden: {{fileCount}}</div>
