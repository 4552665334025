import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { GeolocationState } from './states/geolocation/geolocation.state';
import { LocationButtonComponent } from './components/location-button/location-button.component';
import { ButtonsModule } from '../ui/buttons/buttons.module';

const components = [LocationButtonComponent];
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    NgxsModule.forFeature([GeolocationState]),
    ButtonsModule,
  ],
  exports: components,
})
export class GeolocationModule {}
