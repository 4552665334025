import { init, configureScope } from '@sentry/angular';
import { environment } from './environments/environment';

export const setupSentry = (): void => {
  init({
    dsn:
      'https://55e018ead6b644f090bf0fbbebab2002@o162096.ingest.sentry.io/1236345',
    environment: 'web',
    enabled: environment.production,
  });

  configureScope((scope) => {
    scope.setTag(
      'flavor',
      environment.production ? 'production' : 'development'
    );
  });
};
