import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { OtherDialogComponent } from '../other-dialog/other-dialog.component';

@Component({
  selector: 'app-person-picker',
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonPickerComponent),
      multi: true,
    },
  ],
})
export class PersonPickerComponent implements ControlValueAccessor {
  public value?: string | null;
  public onChange = (_: any): void => {};
  public onTouch = (_: any): void => {};

  public constructor(private dialog: MatDialog) {}

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setValue(value: string | null): void {
    this.value = value;
    this.onChange(value);
  }

  public async openDialog(): Promise<void> {
    const ref = this.dialog.open(OtherDialogComponent, {
      data: {
        title: $localize`:@@otherDialogAboutTitle:Over`,
        description: $localize`:@@otherDialogAboutDescripton:Over wie gaat deze melding`,
        value: this.value !== 'myself' ? this.value : null,
      },
    });

    const result = await firstValueFrom(ref.afterClosed());
    this.setValue(result);
  }
}
