import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  QueryList,
} from '@angular/core';
import { TileComponent } from '../tile/tile.component';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements AfterViewInit {
  @ContentChildren(TileComponent)
  public tiles?: QueryList<TileComponent>;

  public constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    this.init();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.init();
  }

  private init(): void {
    const tiles = this.tiles?.toArray() || [];
    const tileHeight = this.el.nativeElement.offsetHeight / tiles.length;
    let top = 0;

    tiles.map((t, i) => {
      t.init({
        isFirst: i === 0,
        isLast: i === tiles.length - 1,
        tiles: tiles,
        tileHeight: tileHeight,
        top: top,
        index: i,
      });

      top += tileHeight;
    });
  }
}
