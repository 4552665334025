import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import {firstValueFrom} from 'rxjs';
import { FileConverterService } from '../../services/file-converter.service';
import { VideoModalComponent } from '../video-modal/video-modal.component';

@Component({
  selector: 'app-video-input',
  templateUrl: './video-input.component.html',
  styleUrls: ['./video-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoInputComponent),
      multi: true,
    },
  ],
})
export class VideoInputComponent implements ControlValueAccessor {
  public value?: string;
  public isBusy = false;
  public isDirty = false;

  @ViewChild('fileInput', { read: ElementRef })
  public fileInput?: ElementRef;

  @Output()
  public busy = new EventEmitter();

  @Output()
  public dataReady = new EventEmitter();

  public onChange = (_: any | null): void => {};
  public onTouch = (_: any): void => {};

  public constructor(
    private dialog: MatDialog,
    private fileConverter: FileConverterService,
    private deviceService: DeviceDetectorService
  ) {}

  public async onFileInputChange(e: Event): Promise<void> {
    const files = (e.target as any).files;

    if (files?.length === 0) {
      return;
    }

    const base64 = await this.fileConverter.fileToBase64(files[0]);
    this.captureFinished(base64);
  }

  public captureFinished(base64: string): void {
    this.value = base64;
    this.onChange(base64);
    this.dataReady.emit(base64);
  }

  @HostListener('click')
  public async onClick(): Promise<void> {
    if (!this.deviceService.isDesktop()) {
      this.fileInput?.nativeElement.click();
      return;
    }

    const dialogRef = this.dialog.open(VideoModalComponent);
    const file = await firstValueFrom(dialogRef.afterClosed());

    if (!!file) {
      this.captureFinished(file);
    }
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
