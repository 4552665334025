import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { firstValueFrom, from, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { MediaType } from '../../media-type';
import { FileConverterService } from '../../services/file-converter.service';
import {
  StartRecording,
  StopRecording,
} from '../../states/recoder/recorder.actions';
import { RecorderState } from '../../states/recoder/recorder.state';

@Component({
  selector: 'app-audio-input',
  templateUrl: './audio-input.component.html',
  styleUrls: ['./audio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AudioInputComponent),
      multi: true,
    },
  ],
})
export class AudioInputComponent implements ControlValueAccessor {
  public value?: string;
  public isBusy = false;
  public isDirty = false;

  @Output()
  public busy = new EventEmitter();

  @Output()
  public dataReady = new EventEmitter();

  public onChange = (_: any | null): void => {};
  public onTouch = (_: any): void => {};

  public audioInputError?: string;

  public constructor(
    public store: Store,
    public fileConverter: FileConverterService
  ) {}

  @HostListener('click', ['$event'])
  public async onClick(_: Event): Promise<void> {
    const isRecording = this.store.selectSnapshot(RecorderState.isRecording);
    if (isRecording) {
      this.isBusy = false;

      await firstValueFrom(
        this.store
          .dispatch(new StopRecording())
          .pipe(
            map((state: any) => state.recorder.data),
            filter((file: File) => !!file),
            switchMap((file: File) =>
              from(this.fileConverter.fileToBase64(file))
            ),
            tap((data: string) => (this.value = data)),
            tap((data: string) => this.onChange(data)),
            tap((data: string) => this.dataReady.emit(data))
          )
      );

      this.busy.next(this.isBusy);
      return;
    }

    this.store
      .dispatch(new StartRecording(MediaType.Audio))
      .pipe(
        catchError((e) => {
          this.audioInputError = e;
          return throwError(() => e);
        }),
        tap(() => {
          this.isBusy = true;
          this.busy.next(this.isBusy);
        })
      )
      .subscribe();
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
