import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { utc } from 'moment';
import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { GeolocationStateModel } from 'src/app/geolocation/states/geolocation-state-model';
import { FetchLocation } from 'src/app/geolocation/states/geolocation/geolocation.actions';
import { GeolocationState } from 'src/app/geolocation/states/geolocation/geolocation.state';
import { RecorderState } from 'src/app/media/states/recoder/recorder.state';

@Component({
  selector: 'app-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncidentDetailsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IncidentDetailsComponent),
      multi: true,
    },
  ],
})
export class IncidentDetailsComponent
  implements ControlValueAccessor, OnDestroy {
  public value?: string | null;
  public onChange = (_: any): void => {};
  public onTouch = (_: any): void => {};

  public destroyed$ = new Subject<boolean>();

  public maxDate = utc();

  @Input()
  public hideLocationInput = false;

  @Input()
  public descriptionPlaceholder = 'Beschrijf wat er is gebeurd';

  @Output()
  public busy = new EventEmitter<boolean>();

  public isRecording$ = new Subject();

  @Select(GeolocationState.isUpdating)
  public isUpdatingLocation$!: Observable<boolean>;

  @Select(RecorderState.hasData)
  public hasData$!: Observable<boolean>;

  public isBusy$!: Observable<any>;

  public form = new FormGroup(
    {
      location: new FormControl(),
      reportDate: new FormControl(),
      description: new FormControl(),
      recording: new FormControl(),
      latitude: new FormControl(),
      longitude: new FormControl(),
    },
    {
      validators: [
        (c: AbstractControl): null | { invalidDescription: boolean } => {
          const description = c.value.description?.length > 0;
          const recording = c.value.recording?.length > 0;

          if (!description && !recording) {
            return {
              invalidDescription: true,
            };
          }

          return null;
        },
      ],
    }
  );

  public constructor(private store: Store) {
    this.isBusy$ = combineLatest([
      this.isRecording$,
      this.isUpdatingLocation$,
    ]).pipe(
      takeUntil(this.destroyed$),
      map(
        ([isRecording, isUpdatingLocation]) => isRecording || isUpdatingLocation
      ),
      tap((isBusy) => this.busy.emit(isBusy))
    );

    this.isBusy$.subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => this.onChange(data))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public writeValue(value: any): void {
    this.form.patchValue(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public validate(_: FormControl): null | { invalid: boolean } {
    if (this.form.valid) {
      return null;
    }

    return { invalid: true };
  }

  public async updateLocation(): Promise<void> {
    await firstValueFrom(
      this.store.dispatch(new FetchLocation()).pipe(
        map((state: any) => state.geolocation),
        tap((state: GeolocationStateModel) => {
          this.form.get('latitude')?.setValue(state.position?.latitude);
          this.form.get('longitude')?.setValue(state.position?.longitude);
          this.form.get('location')?.setValue(state.address);
        })
      )
    );
  }
}
