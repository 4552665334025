import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PrivacyStatementState } from 'src/app/privacy/states/privacy-statement/privacy-statement.state';

@Injectable()
export class AcceptedPrivacy implements CanActivate {
  public constructor(private store: Store) {}

  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const agreedToPrivacyStatement = this.store.selectSnapshot(
      PrivacyStatementState
    );

    if (agreedToPrivacyStatement === true) {
      return true;
    }

    this.store.dispatch(new Navigate(['/privacy-statement']));
    return false;
  }
}
