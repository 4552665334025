import { Injectable } from '@angular/core';
import {v4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileConverterService {
  public fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result!.toString());
      reader.onerror = (error): void => reject(error);
    });
  }

  public async base64ToFile(base64: string): Promise<File> {
    const res = await fetch(base64);
    const blob = await res.blob();
    return new File([blob], v4());
  }
}
