import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { IncidentTypesService } from '../services/incident-types.service';
import { FetchIncidentTypes } from './incident-types.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@State<Array<any>>({
  name: 'incidentTypes',
  defaults: [],
})
@Injectable()
export class IncidentTypesState implements NgxsOnInit {
  public constructor(
    private store: Store,
    private incidentTypeService: IncidentTypesService
  ) {}

  public ngxsOnInit(): void {
    this.store.dispatch(new FetchIncidentTypes());
  }

  @Action(FetchIncidentTypes)
  public fetchIncidentTypes(ctx: StateContext<Array<any>>): Observable<any> {
    return this.incidentTypeService
      .fetchAll()
      .pipe(tap((incidentTypes: Array<any>) => ctx.setState(incidentTypes)));
  }
}
