import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileComponent } from './components/tile/tile.component';
import { TilesComponent } from './components/tiles/tiles.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [TileComponent, TilesComponent],
  imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
  exports: [TileComponent, TilesComponent],
})
export class TilesModule {}
