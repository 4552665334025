<form [formGroup]="form">
  <div class="form-group" [hidden]="hideLocationInput">
    <label i18n="DescribeEvent|incident location label@@incidentLocationLabel"
      >Locatie incident</label
    >
    <div class="d-flex location">
      <input
        type="text"
        class="form-control"
        minLength="6"
        maxLength="6"
        formControlName="location"
      />
      <app-icon-button
        class="d-flex ml-1"
        [busy]="isUpdatingLocation$ | async"
        icon="/assets/location.svg"
        (click)="updateLocation()"
        ></app-icon-button
      >
    </div>
  </div>

  <div class="form-group mt-1">
    <label i18n="DescribeEvent|incident date label@@incidentDateLabel"
      >Datum incident</label
    >
    <div class="form-control d-flex date">
      <input
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        readonly
        formControlName="reportDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </div>

  <div class="form-group mt-1">
    <label i18n="DescribeEvent|incident description label@@incidentDescriptionLabel">Omschrijving</label>
    <textarea class="form-control" [placeholder]="descriptionPlaceholder" formControlName="description"></textarea>
  </div>

  <div class="form-group mt-1">
    <label i18n="DescribeEvent|rather record label@@ratherRecordLabel">Liever inspreken?</label>

    <app-audio-input (busy)="isRecording$.next($event)" formControlName="recording">
      <div pristine i18n="DescribeEvent|record label@@recordLabel">Neem nu op</div>
      <div dirty i18n="DescribeEvent|record again label@@recordAgainLabel">Neem opnieuw op</div>
      <div busy i18n="DescribeEvent|stop recording label@@stopRecordingLabel">Stop opname</div>
    </app-audio-input>
  </div>
</form>
