import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { SetTheme } from './theme.actions';

@State<string>({
  name: 'theme',
  defaults: 'general',
})
@Injectable()
export class ThemeState {
  private themeData: any = {
    general: {
      primary: 'var(--orange)',
      onPrimary: 'var(--white)',
    },
    soccer: {
      primary: 'var(--green)',
      onPrimary: 'var(--white)',
    },
    about: {
      primary: 'var(--blue)',
      onPrimary: 'var(--white)',
    },
    privacy: {
      primary: 'var(--pink)',
      onPrimary: 'var(--white)',
    },
  };
  public constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store
      .select(RouterState.url)
      .pipe(
        switchMap((url?: string) => {
          let theme = 'general';

          if (url?.includes('soccer')) {
            theme = 'soccer';
          }

          if (url?.includes('about')) {
            theme = 'about';
          }

          if (url?.includes('privacy')) {
            theme = 'privacy';
          }

          return this.store.dispatch(new SetTheme(theme));
        })
      )
      .subscribe();
  }

  @Action(SetTheme)
  public setTheme(ctx: StateContext<string>, { theme }: SetTheme): string {
    const themeData = this.themeData[theme];
    const rootDirective: any = this.document.querySelector(':root');

    for (const key in themeData) {
      rootDirective?.style?.setProperty(`--${key}`, themeData[key]);
    }
    return ctx.setState(theme);
  }
}
