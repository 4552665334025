<ng-container *ngIf="!audioInputError; else error">
  <input type="file" accept="audio/*" />
  <app-icon-button icon="assets/microphone.svg">
    <ng-content *ngIf="!isBusy && !isDirty" select="[pristine]"></ng-content>
    <ng-content *ngIf="!isBusy && isDirty" select="[dirty]"></ng-content>
    <ng-content *ngIf="isBusy" select="[busy]"></ng-content>
  </app-icon-button>
</ng-container>

<ng-template #error>
  <div class="text-danger">{{audioInputError}}</div>
</ng-template>
