import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { TRANSLATIONS } from 'src/dynamic-translations';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiscriminationTypesService {
  public constructor(private http: HttpClient) {}

  public fetchAll(): Observable<Array<any>> {
    return this.http
      .get<Array<any>>(`${environment.apiBaseUrl}/discrimination-types`)
      .pipe(
        switchMap((items) => from(items)),
        map((item) => {
          const translation: any = TRANSLATIONS['discriminationType'].find(
            (t) => t.key === item.slug
          );
          item.label = translation?.translation || item.label;
          return item;
        }),
        toArray(),
        map((discriminationTypes: Array<any>) => discriminationTypes.sort((a, b) => {
          if (a.postion === b.position) {
            return 0;
          }

          return a.position > b.position ? -1 : 1;

        })),
      );
  }
}
