import {
  Component,
  EventEmitter,
  forwardRef,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FileConverterService } from 'src/app/media/services/file-converter.service';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvidenceComponent),
      multi: true,
    },
  ],
})
export class EvidenceComponent implements ControlValueAccessor, OnDestroy {
  public onChange = (_: any): void => {};
  public onTouch = (_: any): void => {};
  public destroyed$ = new Subject();
  public isBusy$ = new Subject<boolean>();

  public fileCount = 0;

  @Output()
  public busy = new EventEmitter();

  public form = new FormGroup({
    image: new FormArray([]),
    video: new FormArray([]),
    audio: new FormArray([]),
  });

  public constructor(private fileConverterService: FileConverterService) {
    this.isBusy$
      .pipe(
        takeUntil(this.destroyed$),
        tap((isBusy) => this.busy.emit(isBusy))
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap(
          (data) =>
            (this.fileCount =
              (data?.image?.length || 0) +
              (data?.video?.length || 0) +
              (data?.audio?.length || 0))
        ),
        tap((data) => this.onChange(data))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }

    const keys = ['image', 'video', 'audio'];

    for (const key of keys) {
      const items = value[key] || [];
      for (const item of items) {
        (this.form.get(key) as FormArray).push(new FormControl(item));
      }
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public addEvidence(type: string, data: string): void {
    (this.form.get(type) as FormArray).push(new FormControl(data));
  }

  public async onFileSelected(type: string, e: Event): Promise<void> {
    const files = (e.target as HTMLInputElement).files;

    if (!files || files?.length === 0) {
      return;
    }

    const file = files[0];
    const data = await this.fileConverterService.fileToBase64(file);
    this.addEvidence(type, data);
  }
}
