<app-tile backgroundColor="#ffffff" height="20vh">
  <h1
    class="h1 text-uppercase text-primary"
    i18n="ContactWithAdb|contact with adb title@@contactWithAdbTitle"
  >
    Melding
  </h1>
</app-tile>

<div class="page-container">
  <app-body-card>
    <h2 class="text-center" i18n="ContactWithAdb|contact with adb text title@@contactWithAdbTextTitle">Ik heb behoefte aan advies en ondersteuning bij deze melding</h2>

    <div class="d-flex my-2">
      <div class="choice">
        <div>Ja</div>
        <mat-slide-toggle [formControl]="contactWithAdb" class="ml-auto"></mat-slide-toggle>
      </div>
    </div>
  </app-body-card>
  <app-button class="my-2" (click)="continue()">
    <span class="mx-auto" i18n="ContactWithAdb|approve button@@approveButton">Akkoord</span>
  </app-button>
</div>
