<div class="view">
  <video [ngClass]="{active: !value}" autoplay (loadeddata)="streamInitialized = true" #video></video>
  <canvas [ngClass]="{active: !!value}" #canvas></canvas>
</div>

<div class="buttons" *ngIf="streamInitialized">
  <app-icon-button *ngIf="!value" class="capture-btn" (click)="capture()"  icon="/assets/record.svg"></app-icon-button>

  <ng-container *ngIf="!!value">
    <app-button class="bg-gray" (click)="reset()">Opnieuw</app-button>
    <app-button class="ml-2" (click)="approve()">OK</app-button>
  </ng-container>
</div>
