import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { AgreeToPrivacyStatement } from './privacy-statement.actions';

@State<boolean>({
  name: 'privacyStatement',
  defaults: false,
})
@Injectable()
export class PrivacyStatementState implements NgxsOnInit {
  public ngxsOnInit(ctx: any): void {
    const alreadyAgreed = localStorage.getItem('agreedToPrivacyStatement');
    ctx.setState(!!alreadyAgreed);
  }

  @Action(AgreeToPrivacyStatement)
  public agreeToPrivacyStatement(ctx: StateContext<boolean>): boolean {
    localStorage.setItem(
      'agreedToPrivacyStatement',
      new Date().getTime().toString()
    );
    return ctx.setState(true);
  }
}
