import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from './buttons/buttons.module';
import { CardsModule } from './cards/cards.module';
import { FormsModule } from './forms/forms.module';
import { IconsModule } from './icons/icons.module';
import { TilesModule } from './tiles/tiles.module';

const modules = [
  ButtonsModule,
  CardsModule,
  FormsModule,
  IconsModule,
  TilesModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  exports: modules,
})
export class UiModule {}
