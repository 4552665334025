import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { ReportTypesService } from '../services/report-types.service';
import { FetchReportTypes } from './report-types.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@State<Array<any>>({
  name: 'reportTypes',
  defaults: [],
})
@Injectable()
export class ReportTypesState implements NgxsOnInit {
  public constructor(
    private store: Store,
    private reportTypeService: ReportTypesService
  ) {}

  public ngxsOnInit(): void {
    this.store.dispatch(new FetchReportTypes());
  }

  @Action(FetchReportTypes)
  public fetchReportTypes(ctx: StateContext<Array<any>>): Observable<any> {
    return this.reportTypeService
      .fetchAll()
      .pipe(tap((reportTypes: Array<any>) => ctx.setState(reportTypes)));
  }
}
