import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecorderService } from './services/recorder.service';
import { FileConverterService } from './services/file-converter.service';
import { NgxsModule } from '@ngxs/store';
import { RecorderState } from './states/recoder/recorder.state';
import { AudioInputComponent } from './components/audio-input/audio-input.component';
import { ButtonsModule } from '../ui/buttons/buttons.module';
import { VideoInputComponent } from './components/video-input/video-input.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';

const components = [
  AudioInputComponent,
  ImageInputComponent,
  ImageModalComponent,
  VideoInputComponent,
  VideoModalComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    NgxsModule.forFeature([RecorderState]),
    ButtonsModule,
  ],
  exports: components,
  providers: [RecorderService, FileConverterService],
})
export class MediaModule {}
