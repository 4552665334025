import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {IPosition} from '../interfaces/position.interface';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public constructor(private http: HttpClient) {}

  public getLocation(): Promise<IPosition> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject('Geolocation service not found');
      }

      navigator.geolocation.getCurrentPosition((position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      });
    });
  }

  public getGeocodeData(
    latitude: number,
    longitude: number
  ): Observable<{
    route?: string;
    streetNumber?: string;
    postalCode?: string;
    locality?: string;
  }> {
    return this.http.get<any>(
      `${environment.apiBaseUrl}/geocoder/${latitude}/${longitude}`,
      {
        headers: {
          'app-platform': 'web',
          'app-build-number': '1',
        },
      }
    );
  }
}
