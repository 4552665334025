<app-tile backgroundColor="#ffffff" height="20vh">
  <h1
    class="h1 text-uppercase text-primary"
    i18n="About|about title@@aboutTitle"
  >
  Over
  </h1>
</app-tile>

<div class="page-container">
  <app-body-card>
    <markdown src="/assets/translations/nl_about_app.md" ngPreserveWhitespaces></markdown>
  </app-body-card>
</div>
