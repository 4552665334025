import { MediaType } from '../../media-type';

export class StartRecording {
  public static readonly type = '[Recorder] Start recording';

  public constructor(public mediaType: MediaType) {};
}

export class StopRecording {
  public static readonly type = '[Recorder] Stop recording';
}
