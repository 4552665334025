import { Component, HostListener } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-prev-button',
  templateUrl: './prev-button.component.html',
  styleUrls: ['./prev-button.component.scss'],
})
export class PrevButtonComponent {
  public constructor(private navigationService: NavigationService) {}

  @HostListener('click')
  public async onClick(): Promise<void> {
    await firstValueFrom(this.navigationService.goToPrev());
  }
}
