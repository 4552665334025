import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { IReport } from '../../interfaces/report.interface';
import { SetContactWithAdb } from '../../states/report/report.actions';
import { ReportState } from '../../states/report/report.state';

@Component({
  selector: 'app-contact-with-adb-page',
  templateUrl: './contact-with-adb-page.component.html',
  styleUrls: ['./contact-with-adb-page.component.scss'],
})
export class ContactWithAdbPageComponent implements OnInit {
  @Select(ReportState)
  public report$!: Observable<IReport>;

  public contactWithAdb = new FormControl(false);

  public constructor(
    private store: Store,
    private navigationService: NavigationService
  ) {}

  public async ngOnInit(): Promise<void> {
    const report = await firstValueFrom(this.report$);
    this.contactWithAdb.setValue(report!.contactWithAdb);
  }

  public async continue(): Promise<void> {
    await firstValueFrom(
      this.store
        .dispatch(new SetContactWithAdb(this.contactWithAdb.value))
        .pipe(switchMap(() => this.navigationService.goToNext()))
    );
  }
}
