import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { from, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IRecorderStateModel } from '../../interfaces/recoder-state-model.interface';
import { RecorderService } from '../../services/recorder.service';
import { StartRecording, StopRecording } from './recorder.actions';

@State<IRecorderStateModel>({
  name: 'recorder',
  defaults: {
    isRecording: false,
    hasData: false,
  },
})
@Injectable()
export class RecorderState {
  public constructor(private recorderService: RecorderService) {}

  @Selector()
  public static isRecording(state: IRecorderStateModel): boolean {
    return state.isRecording;
  }

  @Selector()
  public static hasData(state: IRecorderStateModel): boolean {
    return state.hasData;
  }

  @Selector()
  public static data(state: IRecorderStateModel): File | undefined {
    return state.data;
  }

  @Action(StartRecording)
  public startRecording(
    ctx: StateContext<IRecorderStateModel>,
    { mediaType }: StartRecording
  ): Observable<MediaStream> {
    return from(this.recorderService.startRecording(mediaType)).pipe(
      catchError((e) => {
        ctx.patchState({
          data: undefined,
          hasData: false,
          isRecording: false,
        });

        return throwError(() => e);
      }),
      tap(() => {
        ctx.patchState({
          data: undefined,
          hasData: false,
          isRecording: true,
        });
      })
    );
  }

  @Action(StopRecording)
  public async stopRecording(ctx: StateContext<IRecorderStateModel>): Promise<void> {
    ctx.patchState({ isRecording: false, hasData: true });
    const data = await this.recorderService.stopRecording();
    ctx.patchState({
      data,
    });
  }
}
