import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { serialize } from 'object-to-formdata';
import { firstValueFrom } from 'rxjs';
import { FileConverterService } from 'src/app/media/services/file-converter.service';
import { v4 } from 'uuid';
import { IReport } from '../../interfaces/report.interface';
import { IReportType, ReportTypesState } from '../../report-types';
import { ReportsService } from '../../reports/services/reports.service';
import {
  ResetReport,
  SendReport,
  SetAbout,
  SetClub,
  SetContactInfo,
  SetContactWithAdb,
  SetDiscriminationTypes,
  SetIncidentDetails,
  SetIncidentTypes,
  SetLocation,
  SetLocationIncident,
  SetReportType,
  SetReporterPostalCode,
  SetRow,
  SetSeat,
  SetSection,
  SetShareWithClub,
  UpdateEvidence,
} from './report.actions';

@State<IReport>({
  name: 'report',
  defaults: {},
})
@Injectable()
export class ReportState {
  public constructor(
    private store: Store,
    private reportsService: ReportsService,
    private fileConverterService: FileConverterService
  ) {}

  @Action(ResetReport)
  public resetReport(ctx: StateContext<IReport>): IReport {
    return ctx.setState({
      id: v4(),
    });
  }

  @Action(SetReportType)
  public setReportType(
    ctx: StateContext<IReport>,
    { slug }: SetReportType
  ): IReport {
    const reportTypes = this.store.selectSnapshot(ReportTypesState);
    const reportType = reportTypes.find((r: IReportType) => r.slug === slug);
    return ctx.patchState({ reportType });
  }

  @Action(SetDiscriminationTypes)
  public setDiscriminationType(
    ctx: StateContext<IReport>,
    { discriminationTypes }: SetDiscriminationTypes
  ): IReport {
    return ctx.patchState({ discriminationTypes });
  }

  @Action(SetIncidentTypes)
  public setIncidentType(
    ctx: StateContext<IReport>,
    { incidentTypes }: SetIncidentTypes
  ): IReport {
    return ctx.patchState({ incidentTypes });
  }

  @Action(SetAbout)
  public setAbout(ctx: StateContext<IReport>, { about }: SetAbout): IReport {
    return ctx.patchState({ about });
  }

  @Action(SetIncidentDetails)
  public setIncidentDetails(
    ctx: StateContext<IReport>,
    { description, reportDate, recording }: SetIncidentDetails
  ): IReport {
    return ctx.patchState({
      description,
      reportDate,
      recording,
    });
  }

  @Action(SetLocation)
  public setLocation(
    ctx: StateContext<IReport>,
    { location, latitude, longitude }: SetLocation
  ): IReport {
    return ctx.patchState({
      location,
      latitude,
      longitude,
    });
  }

  @Action(SetLocationIncident)
  public setIncidentLocation(
    ctx: StateContext<IReport>,
    { locationIncident }: SetLocationIncident
  ): IReport {
    return ctx.patchState({ locationIncident });
  }

  @Action(UpdateEvidence)
  public setEvidence(
    ctx: StateContext<IReport>,
    { evidenceType, evidenceItems }: UpdateEvidence
  ): IReport {
    const report = ctx.getState();
    const allItems = [...((report as any)[evidenceType] || [])];

    for (const evidenceItem of evidenceItems || []) {
      const itemsOfType =
        allItems?.filter(
          (i: any) => i.evidenceType === evidenceItem.evidenceType
        ) ?? [];

      if (evidenceType === 'recording' && itemsOfType?.length > 0) {
        const index = allItems.indexOf(itemsOfType[0]);
        allItems[index] = evidenceItem;
        continue;
      }

      const sameDataExists = itemsOfType.find((i) => i === evidenceItem);

      if (!!sameDataExists) {
        continue;
      }

      allItems.push(evidenceItem);
    }

    return ctx.patchState({ [evidenceType]: allItems });
  }

  @Action(SetReporterPostalCode)
  public setReporterPostalCode(
    ctx: StateContext<IReport>,
    { reporterPostalCode }: SetReporterPostalCode
  ): IReport {
    return ctx.patchState({ reporterPostalCode });
  }

  @Action(SetContactInfo)
  public setContactInfo(
    ctx: StateContext<IReport>,
    { reporterPhoneNumber, reporterEmail }: SetContactInfo
  ): IReport {
    return ctx.patchState({ reporterPhoneNumber, reporterEmail });
  }

  @Action(SetClub)
  public setClub(ctx: StateContext<IReport>, { club }: SetClub): IReport {
    return ctx.patchState({ club: club });
  }

  @Action(SetSection)
  public setSection(
    ctx: StateContext<IReport>,
    { section }: SetSection
  ): IReport {
    return ctx.patchState({ section });
  }

  @Action(SetRow)
  public setRow(ctx: StateContext<IReport>, { row }: SetRow): IReport {
    return ctx.patchState({ row });
  }

  @Action(SetSeat)
  public setSeat(ctx: StateContext<IReport>, { seat }: SetSeat): IReport {
    return ctx.patchState({ seat });
  }

  @Action(SetShareWithClub)
  public setShareWithClub(
    ctx: StateContext<IReport>,
    { shareWithClub }: SetShareWithClub
  ): IReport {
    return ctx.patchState({ shareWithClub });
  }

  @Action(SetContactWithAdb)
  public setContactWithAdb(
    ctx: StateContext<IReport>,
    { contactWithAdb }: SetContactWithAdb
  ): IReport {
    return ctx.patchState({ contactWithAdb });
  }

  @Action(SendReport)
  public async sendReport(
    _: StateContext<IReport>,
    { report }: SendReport
  ): Promise<any> {
    const data = { ...report };

    const keys = ['image', 'video', 'audio', 'recording'];

    for (const key of keys) {
      const items = (report as any)[key];
      for (const i in items) {
        const item = items[i as any];
        if (!item) {
          continue;
        }

        (data as any)[key] = await this.fileConverterService.base64ToFile(item);
      }
    }

    const serialized = serialize(data, {
      indices: true,
      nullsAsUndefineds: true,
    });

    return await firstValueFrom(
      this.reportsService.send(report?.reportType?.slug || '', serialized)
    );
  }
}
