import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GeolocationStateModel } from '../../states/geolocation-state-model';
import { FetchLocation } from '../../states/geolocation/geolocation.actions';
import { GeolocationState } from '../../states/geolocation/geolocation.state';

@Component({
  selector: 'app-location-button',
  templateUrl: './location-button.component.html',
  styleUrls: ['./location-button.component.scss'],
})
export class LocationButtonComponent {
  @Output()
  public locationChanged = new EventEmitter();

  @Output()
  public busy = new EventEmitter();

  @Select(GeolocationState.isUpdating)
  public isUpdatingLocation$!: Observable<boolean>;

  public constructor(private store: Store) {
    this.isUpdatingLocation$.subscribe((isUpdating) => {
      this.busy.emit(isUpdating);
    });
  }

  @HostListener('click')
  public async updateLocation(): Promise<void> {
    await firstValueFrom(
      this.store.dispatch(new FetchLocation()).pipe(
        map((state: any) => state.geolocation),
        tap((state: GeolocationStateModel) => {
          this.locationChanged.emit(state.position);
        })
      )
    );
  }
}
