import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDiscriminationType } from 'src/app/reports/discrimination-types';
import { OptionPickerComponent } from '../option-picker/option-picker.component';

@Component({
  selector: 'app-discrimination-type-picker',
  templateUrl: '../option-picker/option-picker.component.html',
  styleUrls: ['../option-picker/option-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DiscriminationTypePickerComponent),
      multi: true,
    },
  ],
})
export class DiscriminationTypePickerComponent extends OptionPickerComponent<IDiscriminationType> {}
