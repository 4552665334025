import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonPickerComponent } from './components/person-picker/person-picker.component';
import { CardsModule } from '../cards/cards.module';
import { DiscriminationTypePickerComponent } from './components/discrimination-type-picker/discrimination-type-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OtherDialogComponent } from './components/other-dialog/other-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '../buttons/buttons.module';
import { IncidentTypePickerComponent } from './components/incident-type-picker/incident-type-picker.component';
import { OptionPickerComponent } from './components/option-picker/option-picker.component';
import { IncidentDetailsComponent } from './components/incident-details/incident-details.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { EvidenceComponent } from './components/evidence/evidence.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MediaModule } from 'src/app/media/media.module';

const components = [
  DiscriminationTypePickerComponent,
  EvidenceComponent,
  IncidentDetailsComponent,
  IncidentTypePickerComponent,
  OptionPickerComponent,
  OtherDialogComponent,
  PersonPickerComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CardsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    ButtonsModule,
    SharedModule,
    MediaModule,
  ],
  exports: components,
})
export class FormsModule {}
