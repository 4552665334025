import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { IPosition } from '../../interfaces/position.interface';
import { GeolocationService } from '../../services/geolocation.service';
import { GeolocationStateModel } from '../geolocation-state-model';
import { FetchLocation } from './geolocation.actions';

@State<GeolocationStateModel>({
  name: 'geolocation',
  defaults: {
    isUpdating: false,
  },
})
@Injectable()
export class GeolocationState {
  @Selector()
  public static isUpdating(state: GeolocationStateModel): boolean {
    return state.isUpdating;
  }

  @Selector()
  public static position(state: GeolocationStateModel): IPosition | undefined {
    return state.position;
  }

  public constructor(private geolocationService: GeolocationService) {}

  @Action(FetchLocation)
  public async fetchLocation(ctx: StateContext<GeolocationStateModel>): Promise<void> {
    ctx.patchState({
      isUpdating: true,
      position: undefined,
    });

    const position = await this.geolocationService.getLocation();

    const addressData = await firstValueFrom(
      this.geolocationService.getGeocodeData(
        position.latitude,
        position.longitude
      )
    );

    const address = [
      `${addressData?.route ?? ''} ${addressData?.streetNumber ?? ''}`.trim(),
      addressData?.postalCode,
      addressData?.locality,
    ]
      .join(', ')
      .trim();

    ctx.patchState({
      position,
      address,
      isUpdating: false,
    });
  }
}
