import { Injectable } from '@angular/core';
import {
  Action,
  State,
  StateContext,
  Store,
  NgxsOnInit,
  Selector,
} from '@ngxs/store';
import { ClubsService } from '../services/clubs.service';
import { FetchClubs } from './clubs.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IClub } from '../interfaces/club.interface';

@State<Array<IClub>>({
  name: 'clubs',
  defaults: [],
})
@Injectable()
export class ClubsState implements NgxsOnInit {
  @Selector()
  public static professional(state: IClub[]): IClub[] {
    return state.filter((c) => c.isProfessional);
  }

  @Selector()
  public static amateur(state: IClub[]): IClub[] {
    return state.filter((c) => c.isAmateur);
  }

  public constructor(private store: Store, private clubService: ClubsService) {}

  public ngxsOnInit(): void {
    this.store.dispatch(new FetchClubs());
  }

  @Action(FetchClubs)
  public fetchClubs(ctx: StateContext<Array<any>>): Observable<any> {
    return this.clubService
      .fetchAll()
      .pipe(tap((clubs: Array<any>) => ctx.setState(clubs)));
  }
}
