import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { NextButtonComponent } from './components/next-button/next-button.component';
import { PrevButtonComponent } from './components/prev-button/prev-button.component';
import { IconsModule } from '../icons/icons.module';

const components = [
  ButtonComponent,
  ButtonsComponent,
  IconButtonComponent,
  NextButtonComponent,
  PrevButtonComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule],
  exports: components,
})
export class ButtonsModule {}
